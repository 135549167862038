import { ApiHosts, handleResponseOld, handleResponseWithNon200Errors, makeGet } from '../../api/helpers';
import { LocationInfo, PaymentStatus } from '@/redux/modules/wonItems/wonItems.types';
import { transform, transformNew } from '../../api/wonLostCatalogsTransform';

/**
 * @category Mainhost API
 * @see fetchWonItems
 */
type FetchWonItemsParams = {
    authToken: string;
    bidderId: number;
    deployment: string;
    page?: number;
    pageSize?: number | 'all';
};

export type FetchWonItemsResponse = {
    data: {
        catalogs: {
            atgpay: boolean;
            catalogId: number;
            currency: string;
            invoice: {
                balanceDue: number;
                disputeReason: any;
                hammerPrice: number;
                invoiceId: any;
                invoiceTotal: number;
                openDispute: any; // intermittent 0, null or "0" coming in here
                paidAmount: number;
                paidStatus: PaymentStatus.Due | PaymentStatus.Paid;
                sent: boolean;
            };
            items: {
                hammerPrice: number;
                hammerPriceAtomic: number;
                itemId: number;
                lotNumber: string;
                title: string;
            }[];
            liveAuctioneersPayments: boolean;
            lotsByOriginationAddress: {
                [addressHash: string]: Omit<LocationInfo, 'itemIds'> & {
                    lots: number[];
                };
            };
            saleStartTs: number;
            sellerId: number;
            sellerName: string;
            title: string;
        }[];
        count: number;
    };
    message: string;
    success: boolean;
};
/**
 * fetches won items for given bidderId via Get to Mainhost API
 * @function fetchWonItems
 * @category Mainhost API
 * @param {FetchWonItemsParams}
 * @see https://mainhost-DEPLOYMENT.liveauctioneers.com/APIPATH/api/bidders/${bidderId}/wonitems
 * @see https://github.com/LIVEauctioneers/mainhost-prod/blob/master/httpdocs/api/bidders.php#L81
 */
export const fetchWonItems = ({ authToken, bidderId, deployment, page = 1, pageSize = 1250 }: FetchWonItemsParams) => {
    return new Promise<FetchWonItemsResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: `billing/bidder/legacy/invoice/bidders/${bidderId}/wonitems`,
            authToken,
            deployment,
            path: ApiHosts.Billing,
            queryParams: {
                client_version: '5.0',
                page,
                pagesize: pageSize,
            },
        });
        request.end((err, response) =>
            handleResponseWithNon200Errors({
                err,
                reject,
                request,
                resolve,
                response,
                transform: transformNew,
            })
        );
    });
};

/**
 * gets disputed won items for given bidderId via Get to Mainhost API
 * @function fetchDisputedWonItems
 * @category Mainhost API
 * @param {FetchWonItemsParams}
 * @see https://mainhost-DEPLOYMENT.liveauctioneers.com/APIPATH/api/bidders/${bidderId}/disputed
 * @see https://github.com/LIVEauctioneers/mainhost-prod/blob/master/httpdocs/api/bidders.php#L104
 */
export const fetchDisputedWonItems = ({
    authToken,
    bidderId,
    deployment,
    page = 1,
    pageSize = 1250,
}: FetchWonItemsParams) => {
    return new Promise<FetchWonItemsResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: `api/bidders/${bidderId}/disputed`,
            authToken,
            deployment,
            path: ApiHosts.Mainhost,
            queryParams: {
                client_version: '5.0',
                page,
                pagesize: pageSize,
            },
        });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response, transform }));
    });
};

export type UnpaidInvoice = {
    catalogId: number;
    dateSent: number; // unix timestamp (seconds since epoch)
    houseId: number;
    invoiceId: number;
    items: [
        {
            itemId: number;
            salePrice: number;
        },
    ];
};

type FetchUnpaidSentInvoicesResponse = {
    payload: {
        unpaidInvoices: UnpaidInvoice[];
    };
};

/**
 * @category Billing API
 * @see fetchUnpaidSentInvoices
 */
type FetchUnpaidSentInvoicesParams = {
    authToken: string;
    bidderId: number;
    deployment: string;
};

/**
 * fetches sent invoices with unpaid won items for given bidderId via Get to Billing API
 * @function fetchUnpaidSentInvoices
 * @category Billing API
 * @param {FetchUnpaidSentInvoicesParams}
 * @see https://api-DEPLOYMENT.liveauctioneers.com/billing/bidder/{bidderid}/invoices/unpaidSent
 * @see https://github.com/LIVEauctioneers/billing/blob/master/handlers/BidderInvoiceHandler.go/HandleGetBidderUnpaidSentInvoices
 */
export const fetchUnpaidSentInvoices = ({ authToken, bidderId, deployment }: FetchUnpaidSentInvoicesParams) => {
    return new Promise<FetchUnpaidSentInvoicesResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: `bidder/invoice/${bidderId}/unpaidSent`,
            authToken,
            deployment,
            path: ApiHosts.Billing,
        });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });
};
