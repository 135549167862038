import { AppDispatch, AppGetState, GlobalState } from '@/redux/store';
import { createSelector } from '@reduxjs/toolkit';
import { getAuthToken } from '@/redux/modules/account/user/user.selectors';
import { getDeployment } from './config';
import { getTopRatedHouses } from '@/redux/api/topRatedSellers';
import ms from 'ms';

/* Action Types */
export const LOAD_TOP_RATED_SELLERS_FAIL = 'la/domain/topRatedSellers/LOAD_FAIL';
export const LOAD_TOP_RATED_SELLERS_REQUEST = 'la/domain/topRatedSellers/LOAD_REQUEST';
export const LOAD_TOP_RATED_SELLERS_SUCCESS = 'la/domain/topRatedSellers/LOAD_SUCCESS';

const REDUX_STORE_TIME = ms('30m');

/* Reducer */
export type TopRatedSellersSlice = {
    loaded: {
        isLoaded: boolean;
        time: number;
    };
    topRatedSellers: any[];
};

export const defaultTopRatedSellersSlice: TopRatedSellersSlice = {
    loaded: {
        isLoaded: false,
        time: 0,
    },
    topRatedSellers: [],
};

export default function reducer(
    state: TopRatedSellersSlice = defaultTopRatedSellersSlice,
    action: any = {}
): TopRatedSellersSlice {
    switch (action.type) {
        case LOAD_TOP_RATED_SELLERS_SUCCESS:
            return {
                ...state,
                loaded: {
                    isLoaded: true,
                    time: action.meta.actionTime,
                },
                topRatedSellers: action.payload,
            };
        default:
            return state;
    }
}

/* Seletors */
const stateSelector = (state: GlobalState) => state;
const topRatedSellersSlice = createSelector(stateSelector, (state) => state.topRatedSellers);

const idSelector = (_: GlobalState, id: number) => id;

const topRatedSellersSelector = createSelector(topRatedSellersSlice, (state) => state.topRatedSellers);

export const isTopRatedHouse = createSelector([topRatedSellersSelector, idSelector], (topRatedSellers, sellerId) =>
    topRatedSellers.includes(sellerId)
);

const getTopRatedSellersLoaded = createSelector(topRatedSellersSlice, (state) => state.loaded);

/* Action Creators */
export const fetchTopSellersIfNeeded = () => async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);
        const loaded = getTopRatedSellersLoaded(state);

        const needsRefresh = !loaded.time || Date.now() - loaded.time > REDUX_STORE_TIME;
        if (loaded.isLoaded && !needsRefresh) {
            return;
        }
        dispatch({
            type: LOAD_TOP_RATED_SELLERS_REQUEST,
        });
        const response = await getTopRatedHouses({ authToken, deployment });
        return dispatch({
            meta: { actionTime: Date.now() },
            payload: response.payload || [],
            type: LOAD_TOP_RATED_SELLERS_SUCCESS,
        });
    } catch (error) {
        return dispatch({
            error: true,
            payload: error,
            type: LOAD_TOP_RATED_SELLERS_FAIL,
        });
    }
};
