import { createSelector } from '@reduxjs/toolkit';
import { GlobalState } from '@/redux/store';
import { PaymentStatus, WON_ITEMS_CACHE_TIME } from '@/redux/modules/wonItems/wonItems.types';

const stateSelector = (state: GlobalState) => state;
const wonItemsSlice = createSelector(stateSelector, (state) => state.wonItems);
const loadedSelector = createSelector(wonItemsSlice, (state) => state.loaded);

export const getTotalWonItemsCount = createSelector(wonItemsSlice, (state) => state.totalItems || 0);
export const getWonItems = createSelector(wonItemsSlice, (state) => state.items || []);
export const getWonItemsPage = createSelector(wonItemsSlice, (state) => state.page || 1);

export const getUnpaidWonCatalogs = createSelector(wonItemsSlice, (state) => {
    const wonCatalogs = state.items || [];

    return wonCatalogs.filter((item) => item.invoice.paidStatus === PaymentStatus.Due);
});

export const getWonItemsPageSize = createSelector(wonItemsSlice, (state) => state.pageSize || 10);

// export const areWonItemsLoading = createSelector(wonItemsSlice, (state) => state.loading);

const catalogIdSelector = (_: GlobalState, catalogId: number) => catalogId;
export const getWonCatalogItemsByLocations = createSelector(
    [wonItemsSlice, catalogIdSelector],
    ({ catalogItemsByLocation }, catalogId) => catalogItemsByLocation[catalogId] ?? {}
);

export const shouldFetchWonItems = createSelector([loadedSelector], (loaded) => {
    if (loaded) {
        const time = Date.now();
        const diff = time - loaded;
        if (diff < WON_ITEMS_CACHE_TIME) {
            return false;
        }
    }
    return true;
});
