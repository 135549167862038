import { createSelector } from '@reduxjs/toolkit';
import { GlobalState } from '@/redux/store';
import ms from 'ms';

const REDUX_STORE_TIME = ms('30m');

const stateSelector = (state: GlobalState) => state;
export const wonItemsCountStateSelector = createSelector(stateSelector, (state) => state.wonItemsCount);

export const wonItemsCountLoadedSelector = createSelector(wonItemsCountStateSelector, (state) => state.loaded);
export const wonItemsCountLoadingSelector = createSelector(wonItemsCountStateSelector, (state) => state.loading);

export const getTotalWonItemsCount = createSelector(wonItemsCountStateSelector, (state) => state.wonItemsCount || 0);

export const shouldFetch = createSelector(
    [wonItemsCountLoadedSelector, wonItemsCountLoadingSelector],
    (loaded, loading) => {
        if (loaded) {
            const time = Date.now();
            const diff = time - loaded;
            if (diff < REDUX_STORE_TIME) {
                return false;
            }
        }
        return !loading;
    }
);
