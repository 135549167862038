import { ApiHosts, handleResponseOld, makeGet } from '@/redux/api/helpers';

/**
 * @category Item API
 * @see fetchUpcomingItemData
 */
type FetchUpcomingDataParams = {
    authToken: string;
    bidderId: number;
    deployment: string;
};

/**
 * @category Item API
 * @see fetchUpcomingItemData
 */
type FetchUpcomingDataResponse = {
    data: number[];
    message: string;
    success: boolean;
};

/**
 * GET request to item-api for bidder saved items that are upcoming for auction
 * @function fetchUpcomingItemData
 * @category Item API
 * @param {FetchUpcomingDataParams}
 * @returns {Promise<FetchUpcomingDataResponse>}
 * @see https://item-api-DEPLOYMENT.liveauctioneers.com/upcoming-items/bidder
 * @see https://github.com/LIVEauctioneers/item-api/blob/master/src/bidder/routes/get-bidder-upcoming-items.ts
 */
export const fetchUpcomingItemData = ({
    authToken,
    bidderId,
    deployment,
}: FetchUpcomingDataParams): Promise<FetchUpcomingDataResponse> =>
    new Promise<FetchUpcomingDataResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'upcoming-items/bidder',
            authToken,
            deployment,
            path: ApiHosts.ItemApi,
            queryParams: { bidderId },
        });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });
