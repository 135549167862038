import { createSelector } from '@reduxjs/toolkit';
import { GlobalState } from '@/redux/store';

/* Action Types */
export const SET_WINDOW_SIZE = 'la/ui/windowSize/SET';

export type WindowSizeSlice = {
    initialWidth: number;
};

export const defaultWindowSizeSlice: WindowSizeSlice = {
    initialWidth: 1024,
};

export default function reducer(state: WindowSizeSlice = defaultWindowSizeSlice, action: any = {}): WindowSizeSlice {
    switch (action.type) {
        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state: GlobalState) => state;
const windowSizeSlice = createSelector(stateSelector, (state) => state.windowSize);

export const getInitialWidth = createSelector(windowSizeSlice, (state): number => state.initialWidth);
