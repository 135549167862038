import {
    ChangeWonItemsPageParams,
    FetchWonItemsForBannerIfNeededParams,
    FetchWonItemsIfNeededParams,
} from '@/redux/modules/wonItems/wonItems.types';
import { createAsyncThunk } from '@/redux/createAsyncThunk';
import {
    fetchDisputedWonItems,
    fetchUnpaidSentInvoices,
    fetchWonItems,
    FetchWonItemsResponse,
    UnpaidInvoice,
} from '@/redux/modules/wonItems/wonItems.api';
import { getAuthToken, getBidderId } from '@/redux/modules/account/user/user.selectors';
import { getCookie, INVOICE_REMINDER_COOKIE, setInvoiceReminderCookie } from '@/redux/modules/cookies';
import { getDeployment } from '@/redux/modules/config';
import { getWonItemsPage, getWonItemsPageSize, shouldFetchWonItems } from '@/redux/modules/wonItems/wonItems.selectors';
import { KameleoonExperiments, KameleoonVariants } from '@/hooks/kameleoon/kameleoonExperiments';

export const checkForUnpaidSentInvoices = createAsyncThunk<void, number>(
    'la/ui/wonItems/checkForUnpaidSentInvoices',
    async (bidderId, { dispatch, getState }) => {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);

        // get list of unpaid, sent invoices
        const response = await fetchUnpaidSentInvoices({
            authToken,
            bidderId,
            deployment,
        });

        if (!Boolean(response?.payload?.unpaidInvoices?.length)) {
            return;
        }

        // get first invoice that was sent since last pay invoice cookie set
        const cookieTimestamp = getCookie(state, INVOICE_REMINDER_COOKIE);
        let invoice: UnpaidInvoice;
        if (!Boolean(cookieTimestamp)) {
            invoice = response.payload.unpaidInvoices[0];
        } else {
            invoice = response.payload.unpaidInvoices.find(
                (unpaidInvoice) => unpaidInvoice.dateSent > Number(cookieTimestamp)
            );
        }

        if (Boolean(invoice)) {
            await dispatch(setInvoiceReminderCookie());
        }
    }
);

type ChangeWonItemsPageResponse =
    | {
          data: FetchWonItemsResponse['data'];
          isPopulated: true;
          page: number;
          pageSize: number | 'all';
      }
    | {
          isPopulated: false;
          page: number;
          pageSize: number | 'all';
      };

export const changeWonItemsPage = createAsyncThunk<ChangeWonItemsPageResponse, ChangeWonItemsPageParams>(
    'la/ui/wonItems/changeWonItemsPage',
    async ({ force, isDisputed, page, pageSize }, { getState }) => {
        const state = getState();
        const oldPage = getWonItemsPage(state);
        const oldPageSize = getWonItemsPageSize(state);

        const hasChangedPageNumber = Boolean(page) && oldPage !== page;
        const hasChangedPageSize = Boolean(pageSize) && oldPageSize !== pageSize;
        const isDirty = hasChangedPageNumber || hasChangedPageSize;

        let response: FetchWonItemsResponse['data'];
        if (isDirty || force) {
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);
            const bidderId = getBidderId(state);

            if (isDisputed) {
                const disputedWonItemsResponse = await fetchDisputedWonItems({
                    authToken,
                    bidderId,
                    deployment,
                    page,
                    pageSize: pageSize ?? oldPageSize,
                });
                response = disputedWonItemsResponse.data;
            } else {
                const wonItemsResponse = await fetchWonItems({
                    authToken,
                    bidderId,
                    deployment,
                    page,
                    pageSize: pageSize ?? oldPageSize,
                });
                response = wonItemsResponse.data;
            }
            return {
                data: response,
                isPopulated: true,
                page,
                pageSize,
            };
        }
        return {
            isPopulated: false,
            page,
            pageSize,
        };
    }
);

export const fetchWonItemsIfNeeded = createAsyncThunk<void, FetchWonItemsIfNeededParams>(
    'la/ui/wonItems/fetchWonItemsIfNeeded',
    async ({ force = false, page, pageSize }, { dispatch, getState }) => {
        const state = getState();
        if (shouldFetchWonItems(state) || force) {
            const fetchPageNumber = page ?? getWonItemsPage(state);
            const fetchPageSize = pageSize ?? getWonItemsPageSize(state);
            await dispatch(
                changeWonItemsPage({
                    force: true,
                    page: fetchPageNumber,
                    pageSize: fetchPageSize,
                })
            );
        }
    }
);

export const fetchWonItemsForBannerIfNeeded = createAsyncThunk<void, FetchWonItemsForBannerIfNeededParams>(
    'la/ui/wonItems/fetchWonItemsForBannerIfNeeded',
    async ({ featureFlagVariants }, { dispatch, getState }) => {
        const state = getState();
        const has100OrLessApprovalsExperiment =
            featureFlagVariants[KameleoonExperiments.ApprovalsBids100OrLess] ===
            KameleoonVariants.ApprovalsBids100OrLess.AutoApprovalsVariant;

        if (shouldFetchWonItems(state) && has100OrLessApprovalsExperiment) {
            await dispatch(
                changeWonItemsPage({
                    force: true,
                    page: 1,
                    pageSize: 24,
                })
            );
        }
    }
);

export const fetchDisputedWonItemsIfNeeded = createAsyncThunk<void, FetchWonItemsIfNeededParams>(
    'la/ui/wonItems/fetchDisputedWonItemsIfNeeded',
    async ({ page, pageSize }, { dispatch, getState }) => {
        const state = getState();
        const fetchPageNumber = page ?? getWonItemsPage(state);
        const fetchPageSize = pageSize ?? getWonItemsPageSize(state);
        await dispatch(
            changeWonItemsPage({
                force: true,
                isDisputed: true,
                page: fetchPageNumber,
                pageSize: fetchPageSize,
            })
        );
    }
);
