import { createAsyncThunk } from '@/redux/createAsyncThunk';
import { fetchWonItemsCount } from './wonItemsCount.api';
import { FetchWonItemsCountResponse, FetchWonItemsPayload } from './wonItemsCount.type';
import { getAuthToken, getBidderId } from '@/redux/modules/account/user/user.selectors';
import { getDeployment } from '../config';
import { shouldFetch } from './wonItemsCount.selectors';

export const loadWonItemsCount = createAsyncThunk<FetchWonItemsCountResponse, FetchWonItemsPayload>(
    'la/domain/wonItemsCount/loadWonItemsCount',
    async (payload = undefined, { getState }) => {
        const state = getState();
        const storedAuthToken = getAuthToken(state);
        const deployment = getDeployment(state);
        const storedBidderId = getBidderId(state);

        const response = await fetchWonItemsCount({
            authToken: payload?.authToken || storedAuthToken,
            bidderId: payload?.bidderId || storedBidderId,
            deployment,
        });

        return {
            actionTime: Date.now(),
            payload: response.payload,
        };
    }
);

export const fetchWonItemsCountIfNeeded = createAsyncThunk<void, FetchWonItemsPayload>(
    'la/domain/wonItemsCount/fetchWonItemsCountIfNeeded',
    async (payload = undefined, { dispatch, getState }) => {
        const state = getState();
        if (shouldFetch(state)) {
            await dispatch(loadWonItemsCount(payload));
        }
    }
);
