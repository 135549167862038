import { KameleoonExperimentsVariantIds } from '@/hooks/kameleoon/kameleoonExperiments';
import { WonCatalog } from '@/types/WonCatalog';
import ms from 'ms';

// if the sale started more than 51 days ago assume the bidder paid
export const ASSUME_PAID_DAYS = 51;
export const WON_ITEMS_CACHE_TIME = ms('30m');

export enum PaymentStatus {
    AssumePaid = 'assumePaid',
    Due = 'due',
    InvoicePending = 'invoicePending',
    Overdue = 'overdue',
    Paid = 'paid',
    Resolved = 'resolved',
}

export type LocationInfo = {
    address1: string;
    address2: string;
    carrierName: string;
    carrierService: string;
    city: string;
    country: string;
    itemIds: number[];
    postalCode: string;
    region: string;
    shippingStatus: string;
    shippingStatusSecondary: string;
    shippingTrackingLink: string;
    shippingTrackingNumber: string;
};

export type WonItemsSlice = {
    catalogItemsByLocation: {
        [catalogId: number]: {
            [houseLocationAddressHash: string]: LocationInfo;
        };
    };
    items: WonCatalog[];
    loaded?: number | undefined;
    loading: boolean;
    page: number;
    pageSize: number | 'all';
    totalItems: number;
};

export const defaultWonItemsSlice: WonItemsSlice = {
    catalogItemsByLocation: {},
    items: [],
    loaded: undefined,
    loading: false,
    page: 1,
    pageSize: 10,
    totalItems: 0,
};

export type FetchWonItemsIfNeededParams = {
    force?: boolean;
    page?: number;
    pageSize?: number | 'all';
};

export type FetchWonItemsForBannerIfNeededParams = {
    featureFlagVariants: KameleoonExperimentsVariantIds | {};
};

export type ChangeWonItemsPageParams = {
    force?: boolean;
    isDisputed?: boolean;
    page: number;
    pageSize: number | 'all';
};
