import { addDays } from 'date-fns';
import { ASSUME_PAID_DAYS, defaultWonItemsSlice, PaymentStatus } from '@/redux/modules/wonItems/wonItems.types';
import { changeWonItemsPage } from '@/redux/modules/wonItems/wonItems.actions';
import { createSlice } from '@reduxjs/toolkit';
import { LOG_OUT_BIDDER, LogOutBidderSuccessAction } from '@/redux/modules/account/logout/logout.actions';
import { safeFromUnixTime } from '@liveauctioneers/caterwaul-components/lib/utils/dates';
import { WonCatalog } from '@/types/WonCatalog';
import caseInsensitiveCompare from '@liveauctioneers/caterwaul-components/lib/utils/caseInsensitiveCompare';
import union from 'lodash/union';

const shouldAssumePaid = (saleStart: Date) => {
    const assumePaidDate = addDays(saleStart, ASSUME_PAID_DAYS);
    return safeFromUnixTime() > assumePaidDate;
};

// This function populates one of the fields that doesn't natively return from the API.
// Thus, we omit those two fields for truth of the incoming param.
const getPaymentStatus = (catalog: Omit<WonCatalog, 'paymentStatus' | 'assumePaid'>) => {
    const assumePaid = shouldAssumePaid(safeFromUnixTime(catalog.saleStartTs));
    const { hammerPrice, openDispute, paidStatus, sent } = catalog.invoice;

    let paymentStatus: PaymentStatus;
    if (catalog.invoice && !catalog.invoice.sent && !assumePaid) {
        paymentStatus = PaymentStatus.InvoicePending;
    } else if (!hammerPrice && sent) {
        paymentStatus = PaymentStatus.Paid;
    } else if (!openDispute && caseInsensitiveCompare(paidStatus, PaymentStatus.Overdue)) {
        paymentStatus = PaymentStatus.Resolved;
    } else if (assumePaid && !openDispute && !caseInsensitiveCompare(paidStatus, PaymentStatus.Paid)) {
        paymentStatus = PaymentStatus.AssumePaid;
    } else if (openDispute) {
        paymentStatus = PaymentStatus.Overdue;
    } else {
        paymentStatus = paidStatus;
    }
    return paymentStatus;
};

const wonItemsSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(changeWonItemsPage.pending, (slice) => {
            slice.loading = true;
        });
        builder.addCase(changeWonItemsPage.fulfilled, (slice, { payload }) => {
            if (payload.isPopulated) {
                const updatedCatalogItemsByLocation = {
                    ...slice.catalogItemsByLocation,
                };
                slice.items = payload.data.catalogs.map((wonCatalog) => {
                    const paymentStatus = getPaymentStatus(wonCatalog);
                    const assumePaid = shouldAssumePaid(safeFromUnixTime(wonCatalog.saleStartTs));

                    if (wonCatalog.lotsByOriginationAddress) {
                        Object.keys(wonCatalog.lotsByOriginationAddress).forEach((addressHash) => {
                            if (!updatedCatalogItemsByLocation[wonCatalog.catalogId]) {
                                updatedCatalogItemsByLocation[wonCatalog.catalogId] = {};
                            }
                            const currentAddress = wonCatalog.lotsByOriginationAddress[addressHash];
                            const currentItemIds =
                                updatedCatalogItemsByLocation[wonCatalog.catalogId][addressHash]?.itemIds ?? [];
                            updatedCatalogItemsByLocation[wonCatalog.catalogId][addressHash] = {
                                address1: currentAddress.address1,
                                address2: currentAddress.address2,
                                carrierName: currentAddress.carrierName,
                                carrierService: currentAddress.carrierService,
                                city: currentAddress.city,
                                country: currentAddress.country,
                                itemIds: union(currentItemIds, currentAddress.lots),
                                postalCode: currentAddress.postalCode,
                                region: currentAddress.region,
                                shippingStatus: currentAddress.shippingStatus,
                                shippingStatusSecondary: currentAddress.shippingStatusSecondary,
                                shippingTrackingLink: currentAddress.shippingTrackingLink,
                                shippingTrackingNumber: currentAddress.shippingTrackingNumber,
                            };
                        });
                    }

                    return {
                        ...wonCatalog,
                        assumePaid,
                        paymentStatus,
                    };
                });
                slice.catalogItemsByLocation = updatedCatalogItemsByLocation;
                slice.totalItems = payload.data.count;
            }
            slice.loaded = Date.now();
            slice.loading = false;
            slice.page = payload.page;
            slice.pageSize = payload.pageSize;
        });
        builder.addCase(changeWonItemsPage.rejected, (slice) => {
            slice.loading = false;
        });

        builder.addCase<typeof LOG_OUT_BIDDER, LogOutBidderSuccessAction>(LOG_OUT_BIDDER, () => ({
            ...defaultWonItemsSlice,
        }));
    },
    initialState: defaultWonItemsSlice,
    name: 'wonItems',
    reducers: {},
});

export const { reducer: wonItemsReducer } = wonItemsSlice;
