import { createSlice } from '@reduxjs/toolkit';
import { defaultUpcomingItemsSlice, UpcomingItemsSlice } from '@/redux/modules/upcoming/items/upcomingItems.types';
import { fetchBidderData } from '@/redux/modules/account/user/user.actions';
import { fetchUpcomingItems } from '@/redux/modules/upcoming/items/upcomingItems.actions';
import { FulfilledActionFromAsyncThunk } from '@/redux/createAsyncThunk';
import { LOG_OUT_BIDDER, LogOutBidderSuccessAction } from '@/redux/modules/account/logout/logout.actions';
import { submitAbsenteeBid } from '@/redux/modules/absenteeBid/absenteeBid.actions';
import { submitLogin, submitLoginWithToken } from '@/redux/modules/account/login/login.actions';
import { submitSaveItem, submitUnsaveItem } from '@/redux/modules/item/saved/savedItems.actions';

const updateItemIdsOnLogin = (
    slice: UpcomingItemsSlice,
    action:
        | FulfilledActionFromAsyncThunk<typeof fetchBidderData>
        | FulfilledActionFromAsyncThunk<typeof submitLogin>
        | FulfilledActionFromAsyncThunk<typeof submitLoginWithToken>
) => {
    const items = new Set([...slice.itemIds]);
    action.payload.biddingInfos?.forEach((item) => {
        items.add(item.itemId);
    });
    slice.itemIds = [...items];
};

const resetSliceToDefault = () => defaultUpcomingItemsSlice;

const upcomingItemsSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(fetchBidderData.fulfilled, updateItemIdsOnLogin);
        builder.addCase(submitLogin.fulfilled, updateItemIdsOnLogin);
        builder.addCase(submitLoginWithToken.fulfilled, updateItemIdsOnLogin);

        builder.addCase(fetchUpcomingItems.pending, (slice) => {
            slice.error = false;
            slice.errorMessage = null;
            slice.submitted = true;
            slice.success = false;
        });
        builder.addCase(fetchUpcomingItems.fulfilled, (slice, { payload }) => {
            slice.itemIds = [...new Set([...slice.itemIds, ...payload])];
            slice.loaded = Date.now();
            slice.submitted = false;
            slice.success = true;
        });
        builder.addCase(fetchUpcomingItems.rejected, (slice, { payload }) => {
            slice.error = true;
            slice.errorMessage = payload.message;
            slice.submitted = false;
            slice.success = false;
        });

        builder.addCase(submitSaveItem.fulfilled, (slice, { payload: response }) => {
            const { meta, payload } = response;
            if (meta?.analytics?.eventPayload?.properties?.biddingInfo?.isAvailable) {
                slice.itemIds = [...new Set([...slice.itemIds, payload])];
            }
        });
        builder.addCase(submitUnsaveItem.fulfilled, (slice, { payload }) => {
            const itemIds = new Set(slice.itemIds);
            itemIds.delete(payload.payload);
            slice.itemIds = [...itemIds];
        });

        builder.addCase(submitAbsenteeBid.fulfilled, (slice, { payload }) => {
            slice.itemIds = [...new Set([...slice.itemIds, payload.meta.itemId])];
        });

        builder.addCase<typeof LOG_OUT_BIDDER, LogOutBidderSuccessAction>(LOG_OUT_BIDDER, resetSliceToDefault);
        builder.addCase(submitLogin.rejected, resetSliceToDefault);
    },
    initialState: defaultUpcomingItemsSlice,
    name: 'upcomingItems',
    reducers: {},
});

export const { reducer: upcomingItemsReducer } = upcomingItemsSlice;
