import { createSelector } from '@reduxjs/toolkit';
import { GlobalState } from '@/redux/store';
import { LOAD_ABOUT_DATA_SUCCESS } from './actions';
import { LOAD_HOME_DATA_SUCCESS } from '@/redux/modules/home/home.actions';

// reducer
export type TrustMetricsSlice = {
    trustMetrics: any;
};

export const defaultTrustMetricsSlice: TrustMetricsSlice = {
    trustMetrics: {},
};

export default function reducer(
    state: TrustMetricsSlice = defaultTrustMetricsSlice,
    action: any = {}
): TrustMetricsSlice {
    switch (action.type) {
        case LOAD_ABOUT_DATA_SUCCESS:
        case LOAD_HOME_DATA_SUCCESS:
            return {
                ...state,
                trustMetrics: { ...action.payload.siteStatistics },
            };
        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state: GlobalState) => state;
const trustMetricsSlice = createSelector(stateSelector, (state) => state.trustMetrics);
export const trustMetricsSelector = createSelector(trustMetricsSlice, (state) => state.trustMetrics);
