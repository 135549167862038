export type WonItemsCountState = {
    loaded: number;
    loading: boolean;
    wonItemsCount: number;
};

export const defaultWonItemsCountState: WonItemsCountState = {
    loaded: 0,
    loading: false,
    wonItemsCount: 0,
};

export type FetchWonItemsPayload = {
    authToken: string;
    bidderId: number;
};

export type FetchWonItemsCountResponse = {
    actionTime: number;
    payload: number;
};
