import { AppDispatch, GlobalState } from '@/redux/store';
import { createSelector } from '@reduxjs/toolkit';

/* Action Types */
export const CHANGE_VIEW_DATA_PAGE = 'la/ui/viewData/page';
export const CHANGE_VIEW_DATA_SEARCH_TERMS = 'la/ui/viewData/searchTerms';
export const CHANGE_VIEW_DATA_TAB = 'la/ui/viewData/tab';

export const MY_SAVED_SEARCH_MANAGE_VIEW_DATA_KEY = 'my-saved-search-manage';

// reducer

export default function reducer(state = {}, action: any = {}): {} {
    switch (action.type) {
        case CHANGE_VIEW_DATA_PAGE:
            return {
                ...state,
                [action.payload.id]: {
                    ...state[action.payload.id],
                    page: action.payload.page,
                },
            };
        case CHANGE_VIEW_DATA_SEARCH_TERMS:
            return {
                ...state,
                [action.payload.id]: {
                    ...state[action.payload.id],
                    searchTerms: action.payload.searchTerms,
                },
            };
        case CHANGE_VIEW_DATA_TAB:
            return {
                ...state,
                [action.payload.id]: {
                    ...state[action.payload.id],
                    tab: action.payload.tab,
                },
            };
        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state: GlobalState) => state;
const viewDataSlice = createSelector(stateSelector, (state) => state.viewData);

const idSelector = (_: GlobalState, id: number) => id;
const whichSelector = (_: GlobalState, id: number, which: string) => which;

export const getCatalogViewData = createSelector([viewDataSlice, idSelector], (state, id) => {
    const viewData = state[`catalog-${id}`] || {};
    return {
        page: viewData.page || 1,
        searchTerms: viewData.searchTerms || '',
    };
});

export const getMyAuctionsViewData = createSelector(viewDataSlice, (state) => {
    const viewData = state['my-auctions'] || {};
    return {
        page: viewData.page || 1,
        searchTerms: viewData.searchTerms || '',
        tab: viewData.tab || '',
    };
});

export const getMySavedSearchManageViewData = createSelector(viewDataSlice, (state) => {
    const viewData = state[MY_SAVED_SEARCH_MANAGE_VIEW_DATA_KEY] || {};
    return {
        page: viewData.page || 1,
        searchTerms: viewData.searchTerms || '',
    };
});

export const getProfileViewData = createSelector(viewDataSlice, (state: any) => {
    const viewData: any = state.profile || {};
    return {
        tab: viewData.tab || '',
    };
});

export const getSavedItemsViewData = createSelector(viewDataSlice, (state) => {
    const viewData = state['saved-items'] || {};
    return {
        page: viewData.page || 1,
        searchTerms: viewData.searchTerms || '',
    };
});

export const getSellerViewData = createSelector([viewDataSlice, idSelector, whichSelector], (state, id, which) => {
    const viewData = state[`seller-${id}-${which}`] || {};
    return {
        page: viewData.page || 1,
        searchTerms: viewData.searchTerms || '',
    };
});

export const getStorefrontViewData = createSelector([viewDataSlice, idSelector], (state, id) => {
    const viewData = state[`storefront-${id}`] || {};
    return {
        page: viewData.page || 1,
        searchTerms: viewData.searchTerms || '',
    };
});

/* ACTION CREATORS */
const changeViewDataPage = (id: string, page: number) => ({
    payload: {
        id,
        page,
    },
    type: CHANGE_VIEW_DATA_PAGE,
});

const changeViewDataSearchTerms = (id: string, searchTerms: string) => ({
    payload: {
        id,
        searchTerms,
    },
    type: CHANGE_VIEW_DATA_SEARCH_TERMS,
});

const changeViewDataTab = (id: string, tab: string) => ({
    payload: {
        id,
        tab,
    },
    type: CHANGE_VIEW_DATA_TAB,
});

export const changeCatalogPage = (catalogId: number, page: number) => changeViewDataPage(`catalog-${catalogId}`, page);

export const changeCatalogSearchTerms = (catalogId: number, searchTerms: string) =>
    changeViewDataSearchTerms(`catalog-${catalogId}`, searchTerms);

export const changeMyAuctionsPage = (page: number) => changeViewDataPage('my-auctions', page);

export const changeMyAuctionsSearchTerms = (searchTerms: string) =>
    changeViewDataSearchTerms('my-auctions', searchTerms);

export const changeMyAuctionsTab = (tab: string) => changeViewDataTab('my-auctions', tab);

export const changeMyBidsPage = (page: number) => changeViewDataPage('my-bids', page);

export const changeMyBidsSearchTerms = (searchTerms: string) => changeViewDataSearchTerms('my-bids', searchTerms);

export const changeMyBidsTab = (tab: string) => changeViewDataTab('my-bids', tab);

export const changeMySavedSearchManagePage = (page: number) =>
    changeViewDataPage(MY_SAVED_SEARCH_MANAGE_VIEW_DATA_KEY, page);

export const changeMySavedSearchSearchManageTerms = (searchTerms: string) =>
    changeViewDataSearchTerms(MY_SAVED_SEARCH_MANAGE_VIEW_DATA_KEY, searchTerms);

export const changeSavedItemsPage = (page: number) => changeViewDataPage('saved-items', page);

export const changeSavedItemsSearchTerms = (searchTerms: string) =>
    changeViewDataSearchTerms('saved-items', searchTerms);

export const changeSellerPageUpcoming = (sellerId: number, page: number) =>
    changeViewDataPage(`seller-${sellerId}-upcoming`, page);

export const changeSellerPagePast = (sellerId: number, page: number) => async (dispatch: AppDispatch) =>
    dispatch(changeViewDataPage(`seller-${sellerId}-past`, page));

export const changeStorefrontPage = (sellerId: number, page: number) => async (dispatch: AppDispatch) =>
    dispatch(changeViewDataPage(`storefront-${sellerId}`, page));

export const changeStoreFrontSearchTerms = (sellerId: number, searchTerms: string) => async (dispatch: AppDispatch) =>
    dispatch(changeViewDataSearchTerms(`storefront-${sellerId}`, searchTerms));

export const changeSellerSearchTermsUpcoming = (sellerId: number, searchTerms: string) =>
    changeViewDataSearchTerms(`seller-${sellerId}-upcoming`, searchTerms);

export const changeSellerSearchTermsPast = (sellerId: number, searchTerms: string) => async (dispatch: AppDispatch) =>
    dispatch(changeViewDataSearchTerms(`seller-${sellerId}-past`, searchTerms));
