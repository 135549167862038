import { createSelector } from '@reduxjs/toolkit';
import { GlobalState } from '@/redux/store';
import {
    LOAD_WHITE_LABEL_STYLES_FAIL,
    LOAD_WHITE_LABEL_STYLES_REQUEST,
    LOAD_WHITE_LABEL_STYLES_SUCCESS,
} from '../modules/actions';
import { WhiteLabelStyle } from '../../types/WhiteLabel';
import theme from '@liveauctioneers/caterwaul-components/lib/theme/theme';

export const defaultWhiteLabelStyle: WhiteLabelStyle = {
    property: 'primaryColor',
    value: theme.colors.blue200,
};

// reducer
export type WhiteLabelSlice = {
    hostname: string;
    isWhiteLabel: boolean;
    loading: boolean;
    logo: string;
    name: string;
    sellerId: number;
    siteName: string;
    siteUrl: string;
    styles: WhiteLabelStyle[];
};

export const defaultWhiteLabelSlice: WhiteLabelSlice = {
    hostname: '',
    isWhiteLabel: false,
    loading: false,
    logo: '',
    name: '',
    sellerId: 0,
    siteName: '',
    siteUrl: '',
    styles: [defaultWhiteLabelStyle],
};

export default function reducer(state: WhiteLabelSlice = defaultWhiteLabelSlice, action: any = {}): WhiteLabelSlice {
    switch (action.type) {
        case LOAD_WHITE_LABEL_STYLES_FAIL:
            return {
                ...state,
                loading: false,
                styles: [defaultWhiteLabelStyle],
            };
        case LOAD_WHITE_LABEL_STYLES_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case LOAD_WHITE_LABEL_STYLES_SUCCESS:
            let newWhiteLabelStyles =
                action.payload && action.payload.length > 0 ? action.payload : [defaultWhiteLabelStyle];

            return {
                ...state,
                loading: false,
                styles: newWhiteLabelStyles,
            };
        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state: GlobalState) => state;
const whiteLabelSlice = createSelector(stateSelector, (state) => state.whiteLabel);

export const getHostName = createSelector(whiteLabelSlice, (state) => {
    return state ? state.hostname : '';
});

export const getWhiteLabelLogo = createSelector(whiteLabelSlice, (state) => state.logo);
export const getWhiteLabelName = createSelector(whiteLabelSlice, (state) => state.name);
export const getWhiteLabelSellerId = createSelector(whiteLabelSlice, (state) => state.sellerId);
export const getWhiteLabelSiteName = createSelector(whiteLabelSlice, (state) => state.siteName);
export const getWhiteLabelSiteUrl = createSelector(whiteLabelSlice, (state) => {
    if (Boolean(state.siteUrl) && !state.siteUrl.includes('http')) {
        return `//${state.siteUrl}`;
    }
    return state.siteUrl;
});
export const getWhiteLabelStyles = createSelector(whiteLabelSlice, (state) => state.styles);
export const getWhiteLabelPrimaryColor = createSelector(whiteLabelSlice, (state) => {
    let whiteLabelStyles = state.styles ? state.styles : [];
    let primaryColor = [...whiteLabelStyles].filter(({ property }) => property === 'primaryColor');
    if (primaryColor.length <= 0) {
        return theme.colors.blue200;
    } else {
        return primaryColor[0].value;
    }
});
export const getIsWhiteLabel = createSelector(whiteLabelSlice, (state) => Boolean(state.isWhiteLabel));
