import { AppDispatch, AppGetState, GlobalState } from '@/redux/store';
import { createSelector } from '@reduxjs/toolkit';
import { getAuthToken } from '@/redux/modules/account/user/user.selectors';
import { getDeployment } from './config';
import api from '@/redux/api/video';
import cloneDeep from 'lodash/cloneDeep';
import difference from 'lodash/difference';
import union from 'lodash/union';

/* Action Types */
const LOAD_STREAM_NAME_FAIL = 'la/domain/video/LOAD_FAIL';
const LOAD_STREAM_NAME_REQUEST = 'la/domain/video/LOAD_REQUEST';
const LOAD_STREAM_NAME_SUCCESS = 'la/domain/video/LOAD_SUCCESS';

export type VideoSlice = {
    byId: {
        [catalogId: number]: {
            status: string;
            streamId: string;
        };
    };
    loaded: {
        [catalogId: number]: number;
    };
    loading: number[];
};

export const defaultVideoSlice: VideoSlice = {
    byId: {},
    loaded: {},
    loading: [],
};

export default function reducer(state: VideoSlice = defaultVideoSlice, action: any = {}): VideoSlice {
    let existing: VideoSlice['byId'];
    let loaded: VideoSlice['loaded'];
    let loading: VideoSlice['loading'];
    let time: number;

    switch (action.type) {
        case LOAD_STREAM_NAME_FAIL:
            return {
                ...state,
                loading: difference(state.loading, [action.meta.catalogId]),
            };
        case LOAD_STREAM_NAME_REQUEST:
            return {
                ...state,
                loading: union(state.loading, [action.payload]),
            };
        case LOAD_STREAM_NAME_SUCCESS:
            time = action.meta.actionTime;
            existing = cloneDeep(state.byId);
            loaded = { ...state.loaded, [action.meta.catalogId]: time };
            loading = cloneDeep(state.loading);
            existing[action.meta.catalogId] = action.payload;
            loading = difference(loading, [action.meta.catalogId]);
            return {
                ...state,
                byId: existing,
                loaded,
                loading,
            };
        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state: GlobalState) => state;
const videoSlice = createSelector(stateSelector, (state) => state.video);

const idSelector = (_: GlobalState, id: number) => id;

const byIdSelector = createSelector(videoSlice, (state) => state.byId);

const loadingSelector = createSelector(videoSlice, (state) => state.loading);

export const getStreamStatus = createSelector(
    [byIdSelector, idSelector],
    (byId, id) => byId[id] || { status: '', streamId: '' }
);

export const hasVideoStream = createSelector(getStreamStatus, (streamStatus) => Boolean(streamStatus?.streamId));

const isStreamIdLoading = createSelector([loadingSelector, idSelector], (loading, id) => loading.includes(id));

/* ACTION CREATORS */
const loadStreamId = (catalogId) => async (dispatch, getState) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);
        dispatch({
            meta: { actionTime: Date.now() },
            payload: catalogId,
            type: LOAD_STREAM_NAME_REQUEST,
        });
        const { payload } = await api.fetchStreamId({
            authToken,
            catalogId,
            deployment,
        });

        dispatch({
            meta: { actionTime: Date.now(), catalogId },
            payload,
            type: LOAD_STREAM_NAME_SUCCESS,
        });
    } catch (error) {
        dispatch({
            error: true,
            meta: { catalogId },
            payload: error,
            type: LOAD_STREAM_NAME_FAIL,
        });
    }
};

export const fetchStreamIdIfNeeded = (catalogId: number) => async (dispatch: AppDispatch, getState: AppGetState) => {
    if (!isStreamIdLoading(getState(), catalogId)) {
        return dispatch(loadStreamId(catalogId));
    }
    return Promise.resolve();
};
