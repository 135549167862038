import { absenteeBidReducer } from './modules/absenteeBid/absenteeBid.reducer';
import { reducer as addSearchAlert } from './modules/addSearchAlert';
import { reducer as analytics } from './modules/analytics';
import { auctionCalendarReducer } from './modules/auctionCalendar/auctionCalendar.reducer';
import { auctionsNearMeReducer } from './modules/auctionsNearMe/auctionsNearMe.reducer';
import { autoPopModalReducer } from './modules/autoPopModal/autoPopModal.reducer';
import { bankAccount } from './modules/bankAccount';
import { bidderBiddingInfoAllReducer } from './modules/bidder/biddingInfoAll/bidderBiddingInfoAll.reducer';
import { bidderCardStatusReducer } from './modules/bidder/cardStatus/bidderCardStatus.reducer';
import { bidderExistsReducer } from './modules/bidder/exists/bidderExists.reducer';
import { bidderPreferencesReducer } from './modules/bidder/preferences/bidderPreferences.reducer';
import { reducer as bidderRecentBidCount } from '@/redux/modules/bidder/recentBidCount/bidderRecentBidCount.reducer';
import { bidIncrementsReducer } from './modules/bidIncrements/bidIncrements.reducer';
import { bottomCardReducer } from './modules/bottomCard/bottomCard.reducer';
import { catalogAdReducer } from '@/redux/modules/catalog/ad/catalogAd.reducer';
import { catalogAnnouncementReducer } from './modules/catalog/announcement/catalogAnnouncement.reducer';
import { catalogDetailReducer } from './modules/catalog/detail/catalogDetail.reducer';
import { catalogItemsReducer } from './modules/catalog/items/catalogItems.reducer';
import { catalogReducer } from '@/redux/modules/catalog/catalogs/catalog.reducer';
import { catalogRegistrationReducer } from './modules/catalog/registration/catalogRegistration.reducer';
import { categoryReducer } from './modules/category/category.reducer';
import { reducer as certCapture } from './modules/certCapture';
import { changePasswordReducer } from '@/redux/modules/changePassword/changePassword.reducer';
import { checkoutReducer } from './modules/checkout/checkout.reducer';
import { checkoutShippingQuoteReducer } from './modules/checkoutShippingQuote/checkoutShippingQuote.reducer';
import { clientReducer } from './modules/client';
import { combineReducers } from 'redux';
import { confirmPasswordReducer } from './modules/confirmPassword/confirmPassword.reducer';
import { consentReducer } from './modules/consent/consent.reducer';
import { conversationFoldersReducer } from './modules/conversation/folders/conversationFolders.reducer';
import { conversationMessagesReducer } from './modules/conversation/messages/conversationMessages.reducer';
import { conversationReducer } from './modules/conversation/conversations/conversation.reducer';
import { createAccountReducer } from './modules/account/createAccount/createAccount.reducer';
import { deleteAccountReducer } from './modules/account/deleteAccount/deleteAccount.reducer';
import { favoriteItemsReducer } from './modules/favorites/favorites.reducer';
import { followSeller } from './modules/followSeller';
import { helpModalReducer } from './modules/helpModalSlice/helpModalSlice.reducer';
import { homeReducer } from './modules/home/home.reducer';
import { itemCarouselReducer } from '@/redux/modules/itemCarousel/itemCarousel.reducer';
import { itemSummaryReducer } from './modules/item/summary/itemSummary.reducer';
import { kameleoonReducer } from './modules/kameleoon/kameleoon.reducer';
import { loginReducer } from '@/redux/modules/account/login/login.reducer';
import { paginationCookiesReducer, paginationLocalStorageReducer } from './modules/pagination';
import { phoneVerifyReducer } from './modules/phoneVerify/phoneVerify.reducer';
import { priceResultsReducer } from './modules/priceResults/priceResults.reducer';
import { reducer as reseller } from './modules/reseller';
import { retractBidReducer } from './modules/retractBid/retractBid.reducer';
import { savedItemsReducer } from '@/redux/modules/item/saved/savedItems.reducer';
import { savedSearch } from './modules/savedSearch';
import { searchExclusionsReducer } from './modules/search/exclusions/searchExclusions.reducer';
import { searchReducer } from './modules/search/search.reducer';
import { shippingQuoteReducer } from '@/redux/modules/shippingQuote/shippingQuote.reducer';
import { shippingReducer } from './modules/shipping/shipping.reducer';
import { shouldLoginReducer } from '@/redux/modules/account/shouldLogin/shouldLogin.reducer';
import { twoStepAuthenticationReducer } from '@/redux/modules/account/twoStepAuthentication/twoStepAuthentication.reducer';
import { upcomingItemsReducer } from './modules/upcoming/items/upcomingItems.reducer';
import { userBidPlacedItemsSliceReducer } from './modules/item/userBidPlacedItems/userBidPlacedItems.reducer';
import { userReducer } from '@/redux/modules/account/user/user.reducer';
import { walkthroughsReducer } from './modules/walkthroughs/walkthroughs.reducer';
import { wonItemsCountReducer } from './modules/wonItemsCount/wonItemsCount.reducer';
import { wonItemsReducer } from './modules/wonItems/wonItems.reducer';
import apiPerformanceStats from './modules/apiPerformanceStats';
import bidCountDown from './modules/bidCountDown';
import bidHistory from './modules/bidHistory';
import bidLimit from './modules/bidLimit';
import bidLimitExceeded from './modules/bidLimitExceeded';
import browser from './modules/browser';
import categoryCatalogCards from './modules/categoryCatalogCards';
import categoryRelatedSearches from './modules/categoryRelatedSearches';
import combinationCategory from './modules/combinationCategory';
import completeAccount from './modules/completeAccount';
import config from './modules/config';
import consignment from './modules/consignment';
import console from './modules/console';
import consoleBranding from './modules/consoleBranding';
import consoleMessages from './modules/consoleMessages';
import cookies from './modules/cookies';
import creditCard from './modules/creditCard';
import curatedCollection from './modules/curatedCollection';
import currency from './modules/currency';
import discover from './modules/discover';
import emailList from './modules/emailList';
import errors from './modules/errors';
import featuredCatalogs from './modules/featuredCatalogs';
import featuredCategories from './modules/featuredCategories';
import feedback from './modules/feedback';
import forgotPassword from './modules/forgotPassword';
import geoArea from '@/redux/modules/geoArea/geoArea';
import headerSearchDropdown from './modules/headerSearchDropdown';
import healthcheck from './modules/healthcheck';
import intl from './modules/intl';
import itemDetail from './modules/itemDetail';
import itemFacets from './modules/itemFacets';
import itemRemovalResponse from './modules/itemRemovalResponse';
import landingPage from './modules/landingPage';
import listingAgent from './modules/listingAgent';
import liveBid from './modules/liveBid';
import liveCatalogStatus from './modules/liveCatalogStatus';
import liveItemStatus from './modules/liveItemStatus';
import makeOffer from './modules/makeOffer';
import modal from './modules/modal';
import notification from './modules/notification';
import otherCategories from './modules/otherCategories/otherCategories';
import passwordReset from './modules/passwordReset';
import payment from './modules/payment';
import processingFee from './modules/processingFee';
import recentItem from './modules/recentItem';
import recommendedItem from './modules/recommendedItem';
import registerForCatalog from './modules/registerForCatalog';
import review from './modules/review';
import reviewStatus from './modules/reviewStatus';
import savedItemCount from './modules/savedItemCount';
import saveSearch from './modules/saveSearch';
import searchFacets from './modules/searchFacets';
import searchPromoted from './modules/searchPromoted';
import searchSuggestions from './modules/searchSuggestions';
import seller from './modules/seller';
import sellerCatalogCounts from './modules/sellerCatalogCounts';
import sellerDetail from './modules/sellerDetail';
import sellerEndedCatalogs from './modules/sellerEndedCatalogs';
import sellerFollowerCount from './modules/sellerFollowerCount';
import sellerPreviewCatalogs from './modules/sellerPreviewCatalogs';
import sellerRatings from './modules/sellerRatings';
import sellerRecordResults from './modules/sellerRecordResults';
import sellerUpcomingCatalogs from './modules/sellerUpcomingCatalogs';
import sendSellerMessage from './modules/sendSellerMessage';
import setPassword from './modules/setPassword';
import signifyd from './modules/signifyd';
import similarItems from './modules/similarItems';
import similarItemsCategories from './modules/similarItemsCategories';
import similarSoldItems from './modules/similarSoldItems';
import smsAlerts from './modules/smsAlerts';
import storefront from './modules/storefront';
import subcategoryTiles from './modules/subcategoryTiles';
import suggestedCategoryTiles from './modules/suggestedCategoryTiles';
import taxes from './modules/taxes';
import topRatedSellers from './modules/topRatedSellers';
import trustMetrics from './modules/trustMetrics';
import upcomingCatalogs from './modules/upcomingCatalogs';
import updateAccount from '@/redux/modules/account/updateAccount/updateAccount';
import video from './modules/video';
import viewData from './modules/viewData';
import waitingForApproval from './modules/waitingForApproval';
import whiteLabel from './modules/whiteLabel';
import windowSize from './modules/windowSize';

export default () =>
    combineReducers({
        absenteeBid: absenteeBidReducer,
        addSearchAlert,
        analytics,
        apiPerformanceStats,
        auctionCalendar: auctionCalendarReducer,
        auctionsNearMe: auctionsNearMeReducer,
        autoPopModal: autoPopModalReducer,
        bankAccount,
        bidCountDown,
        bidderBiddingInfoAll: bidderBiddingInfoAllReducer,
        bidderCardStatus: bidderCardStatusReducer,
        bidderExists: bidderExistsReducer,
        bidderPreferences: bidderPreferencesReducer,
        bidderRecentBidCount,
        bidHistory,
        bidIncrements: bidIncrementsReducer,
        bidLimit,
        bidLimitExceeded,
        bottomCard: bottomCardReducer,
        browser,
        catalog: catalogReducer,
        catalogAd: catalogAdReducer,
        catalogAnnouncement: catalogAnnouncementReducer,
        catalogDetail: catalogDetailReducer,
        catalogItems: catalogItemsReducer,
        catalogRegistration: catalogRegistrationReducer,
        category: categoryReducer,
        categoryCatalogCards,
        categoryRelatedSearches,
        certCapture,
        changePassword: changePasswordReducer,
        checkout: checkoutReducer,
        checkoutShippingQuote: checkoutShippingQuoteReducer,
        client: clientReducer,
        combinationCategory,
        completeAccount,
        config,
        confirmPassword: confirmPasswordReducer,
        consent: consentReducer,
        consignment,
        console,
        consoleBranding,
        consoleMessages,
        conversation: conversationReducer,
        conversationFolders: conversationFoldersReducer,
        conversationMessages: conversationMessagesReducer,
        cookies,
        createAccount: createAccountReducer,
        creditCard,
        curatedCollection,
        currency,
        deleteAccount: deleteAccountReducer,
        discover,
        emailList,
        errors,
        favoriteItems: favoriteItemsReducer,
        featuredCatalogs,
        featuredCategories,
        feedback,
        followSeller,
        forgotPassword,
        geoArea,
        healthcheck,
        helpModal: helpModalReducer,
        home: homeReducer,
        intl,
        // item,
        itemCarousel: itemCarouselReducer,
        itemDetail,
        itemFacets,
        itemRemovalResponse,
        itemSummary: itemSummaryReducer,
        kameleoonExperiments: kameleoonReducer,
        landingPage,
        listingAgent,
        liveBid,
        liveCatalogStatus,
        liveItemStatus,
        login: loginReducer,
        makeOffer,
        modal,
        notification,
        otherCategories,
        pagination: paginationCookiesReducer,
        passwordReset,
        payment,
        persist: combineReducers({
            headerSearchDropdown,
            pagination: paginationLocalStorageReducer,
            walkthroughs: walkthroughsReducer,
        }),
        phoneVerify: phoneVerifyReducer,
        priceResults: priceResultsReducer,
        processingFee,
        recentItem,
        recommendedItem,
        registerForCatalog,
        reseller,
        retractBid: retractBidReducer,
        review,
        reviewStatus,
        savedItemCount,
        savedItems: savedItemsReducer,
        savedSearch,
        saveSearch,
        search: searchReducer,
        searchExclusions: searchExclusionsReducer,
        searchFacets,
        searchPromoted,
        searchSuggestions,
        seller,
        sellerCatalogCounts,
        sellerDetail,
        sellerEndedCatalogs,
        sellerFollowerCount,
        sellerPreviewCatalogs,
        sellerRatings,
        sellerRecordResults,
        sellerUpcomingCatalogs,
        sendSellerMessage,
        setPassword,
        shipping: shippingReducer,
        shippingQuote: shippingQuoteReducer,
        shouldLogin: shouldLoginReducer,
        signifyd,
        similarItems,
        similarItemsCategories,
        similarSoldItems,
        smsAlerts,
        storefront,
        subcategoryTiles,
        suggestedCategoryTiles,
        taxes,
        topRatedSellers,
        trustMetrics,
        twoStepAuthentication: twoStepAuthenticationReducer,
        upcomingCatalogs,
        upcomingItems: upcomingItemsReducer,
        updateAccount,
        user: userReducer,
        userBidPlacedItems: userBidPlacedItemsSliceReducer,
        video,
        viewData,
        waitingForApproval,
        whiteLabel,
        windowSize,
        wonItems: wonItemsReducer,
        wonItemsCount: wonItemsCountReducer,
    });
