import { ApiHosts, handleResponseOld, makeGet } from '../../api/helpers';

type FetchWonItemsCountParams = {
    authToken: string;
    bidderId: number;
    deployment: string;
};

export type FetchWonItemsCountResponse = {
    error: boolean;
    payload: number;
};
/**
 * gets won items count for given bidderId via Get to Billing API
 * @function fetchWonItemsCount
 * @category Billing API
 * @param {FetchWonItemsCountParams}
 * @see https://billing-DEPLOYMENT.liveauctioneers.com/billing/bidder/${bidderId}/wonitems
 * @see https://github.com/LIVEauctioneers/billing/tree/master/server
 */
export const fetchWonItemsCount = ({ authToken, bidderId, deployment }: FetchWonItemsCountParams) => {
    return new Promise<FetchWonItemsCountResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: `billing/bidder/${bidderId}/wonitems`,
            authToken,
            deployment,
            path: ApiHosts.Billing,
        });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });
};
