import {
    AccountHolderWalkthroughSteps,
    FirstTimeWalkthroughSteps,
    NewAccountWalkthroughSteps,
    OnboardingWalkthroughSteps,
} from '@/types/walkthroughs';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { defaultWalkthroughsSlice, NavigationWalkthroughHydrationAction } from './walkthroughs.types';
import { FORCE_ENABLE_NOTIFICATIONS_BANNER_DISMISS } from '../notification';
import { LocationChangeAction, ROUTER_LOCATION_CHANGE } from '@/types/router/routerLocationChange';
import { nullCoalesceSameStructure } from '@/utils/nullCoalesceSameStructure';
import { REHYDRATE } from 'redux-persist/constants';
import { submitCreateAccount } from '@/redux/modules/account/createAccount/createAccount.actions';
import cloneDeep from 'lodash/cloneDeep';

// For REHYDRATE
const typedNCSS = (
    slice: any,
    payload: NavigationWalkthroughHydrationAction['payload'],
    key1: 'onboardingWalkthrough',
    key2: keyof (typeof defaultWalkthroughsSlice)['onboardingWalkthrough']
) => {
    nullCoalesceSameStructure(slice, payload?.persist?.walkthroughs, defaultWalkthroughsSlice, key1, key2);
};

export const walkthroughsSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase<typeof REHYDRATE, NavigationWalkthroughHydrationAction>(REHYDRATE, (slice, { payload }) => {
            // Load the walkthroughs
            // ! CAREFUL !
            // Must do step by step to avoid situation explained in this slack link
            //   https://liveauctioneers.slack.com/archives/C9LU1NR5Y/p1656348993436049?thread_ts=1656336397.945929&cid=C9LU1NR5Y
            //   Transform doesn't want to run otherwise we'd do it there.

            // Onboarding Properties
            typedNCSS(slice, payload, 'onboardingWalkthrough', 'currentStep');
            typedNCSS(slice, payload, 'onboardingWalkthrough', 'notFirstTimeOnPage');

            // Make sure we don't start open
            slice.onboardingWalkthrough.isOpen = false;

            // Track hydration
            slice.onboardingWalkthrough.hasHydrated = true;

            // If you've seen the returning tip, remove the returning tip!
            if (
                slice.onboardingWalkthrough.notFirstTimeOnPage &&
                slice.onboardingWalkthrough.currentStep === AccountHolderWalkthroughSteps.Returning
            ) {
                slice.onboardingWalkthrough.currentStep = NewAccountWalkthroughSteps.FirstTimeAnyPage;
            }

            // Track second time on page accurately, and
            // Skip first walkthrough flow if second visit
            if (
                slice.onboardingWalkthrough.currentStep === FirstTimeWalkthroughSteps.FutureItem ||
                slice.onboardingWalkthrough.currentStep === FirstTimeWalkthroughSteps.LiveItem ||
                slice.onboardingWalkthrough.currentStep === FirstTimeWalkthroughSteps.Search ||
                slice.onboardingWalkthrough.currentStep === AccountHolderWalkthroughSteps.Returning
            ) {
                slice.onboardingWalkthrough.notFirstTimeOnPage = true;
                slice.onboardingWalkthrough.currentStep = AccountHolderWalkthroughSteps.Returning;
            }
        });

        // todo: fix type
        builder.addCase<typeof ROUTER_LOCATION_CHANGE, LocationChangeAction>(ROUTER_LOCATION_CHANGE, (slice) => {
            // If a user tries to dismiss by navigating elsewhere, let them.
            if (
                slice.onboardingWalkthrough.isOpen &&
                slice.onboardingWalkthrough.currentStep === AccountHolderWalkthroughSteps.Returning
            ) {
                slice.onboardingWalkthrough.currentStep = NewAccountWalkthroughSteps.FirstTimeAnyPage;
            }

            // Make sure that walkthroughs close whenever you navigate to a new location.
            slice.onboardingWalkthrough.isOpen = false;
        });

        builder.addCase<typeof FORCE_ENABLE_NOTIFICATIONS_BANNER_DISMISS, any>(
            FORCE_ENABLE_NOTIFICATIONS_BANNER_DISMISS,
            (slice) => {
                // I just want to trigger rerender
                slice.onboardingWalkthrough = cloneDeep(slice.onboardingWalkthrough);
            }
        );

        builder.addCase(submitCreateAccount.fulfilled, (slice) => {
            // Any account creation should skip previous steps.
            slice.onboardingWalkthrough.currentStep = NewAccountWalkthroughSteps.FirstTimeAnyPage;
        });
    },
    initialState: defaultWalkthroughsSlice,
    name: 'walkthroughs',
    reducers: {
        setOnboardingWalkthroughOpenProperty: (state, action: PayloadAction<boolean>) => {
            state.onboardingWalkthrough.isOpen = action.payload;
        },
        setOnboardingWalkthroughStep: (state, action: PayloadAction<OnboardingWalkthroughSteps>) => {
            state.onboardingWalkthrough.currentStep = action.payload;
        },
    },
});

export const { reducer: walkthroughsReducer } = walkthroughsSlice;

export const { setOnboardingWalkthroughOpenProperty, setOnboardingWalkthroughStep } = walkthroughsSlice.actions;
