import ms from 'ms';

export const UPCOMING_ITEMS_CACHE_TIME = ms('30m');

export type UpcomingItemsSlice = {
    error: boolean;
    errorMessage?: string | null;
    itemIds: number[];
    loaded: number;
    submitted: boolean;
    success: boolean;
};

export const defaultUpcomingItemsSlice: UpcomingItemsSlice = {
    error: false,
    errorMessage: null,
    itemIds: [],
    loaded: null,
    submitted: false,
    success: false,
};
