import { FirstTimeWalkthroughSteps, OnboardingWalkthroughSteps } from '@/types/walkthroughs';
import { REHYDRATE } from 'redux-persist/constants';

export type WalkthroughsState = {
    onboardingWalkthrough: {
        currentStep: OnboardingWalkthroughSteps;
        hasHydrated: boolean;
        isOpen: boolean;
        notFirstTimeOnPage: boolean;
    };
};

export const defaultWalkthroughsSlice: WalkthroughsState = {
    onboardingWalkthrough: {
        currentStep: FirstTimeWalkthroughSteps.Landing,
        hasHydrated: false,
        isOpen: false,
        notFirstTimeOnPage: false,
    },
};

export type NavigationWalkthroughHydrationAction = {
    payload: {
        persist: {
            walkthroughs: WalkthroughsState;
        };
    };
    type: typeof REHYDRATE;
};
