import { createSlice } from '@reduxjs/toolkit';
import { defaultWonItemsCountState } from './wonItemsCount.type';
import { loadWonItemsCount } from './wonItemsCount.actions';

const wonItemsCountSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(loadWonItemsCount.pending, (state) => {
            state.wonItemsCount = 0;
            state.loading = true;
        });

        builder.addCase(loadWonItemsCount.fulfilled, (state, { payload }) => {
            state.loaded = payload.actionTime;
            state.wonItemsCount = payload.payload;
            state.loading = false;
        });

        builder.addCase(loadWonItemsCount.rejected, (state) => {
            state.wonItemsCount = 0;
            state.loading = false;
        });
    },
    initialState: defaultWonItemsCountState,
    name: 'wonItemsCount',
    reducers: {},
});

export const { reducer: wonItemsCountReducer } = wonItemsCountSlice;
