import { _DeepPartial } from 'utility-types/dist/mapped-types';
import { createStore } from '@/redux/create';
import 'core-js/stable';
import 'regenerator-runtime/runtime';

/**
 * Creates the redux store for the client-side from the window.__data object passed from the server-side render
 */
export const getInitialClientStore = () => {
    const store = createStore({ initialData: window.__data });
    delete window.__data;
    return store;
};

/**
 * Creates the redux store for server-side rendering the app
 */
export const getInitialServerStore = (initialData?: _DeepPartial<GlobalState>) => createStore({ initialData });

type EnhancedStore = ReturnType<typeof createStore>;

export type GlobalState = ReturnType<EnhancedStore['getState']>;
export type AppDispatch = ReturnType<EnhancedStore['dispatch']>;
export type AppGetState = () => GlobalState;
