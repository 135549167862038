import { AsyncThunkConfigWithRejectValue, createAsyncThunk } from '@/redux/createAsyncThunk';
import { fetchUpcomingItemData } from '@/redux/modules/upcoming/items/upcomingItems.api';
import { getAuthToken, getBidderId } from '@/redux/modules/account/user/user.selectors';
import { getDeployment } from '@/redux/modules/config';
import { shouldFetchUpcomingItems } from '@/redux/modules/upcoming/items/upcomingItems.selectors';

export const fetchUpcomingItems = createAsyncThunk<number[], void, AsyncThunkConfigWithRejectValue>(
    'la/ui/upcomingItems/fetchUpcomingItems',
    async (_, { getState, rejectWithValue }) => {
        const state = getState();
        const deployment = getDeployment(state);
        const authToken = getAuthToken(state);
        const bidderId = getBidderId(state);

        try {
            const { data } = await fetchUpcomingItemData({
                authToken,
                bidderId,
                deployment,
            });
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const fetchUpcomingItemsIfNeeded = createAsyncThunk<void>(
    'la/ui/upcomingItems/fetchUpcomingItemsIfNeeded',
    async (_, { dispatch, getState }) => {
        const state = getState();
        const bidderId = getBidderId(state);
        const authToken = getAuthToken(state);

        if (bidderId > 0 && Boolean(authToken) && shouldFetchUpcomingItems(state)) {
            await dispatch(fetchUpcomingItems());
        }
    }
);
