import { AppDispatch, AppGetState, GlobalState } from '@/redux/store';
import { createSelector } from '@reduxjs/toolkit';
import { fetchUpcomingCatalogs } from './catalog/catalogs/catalog.api';
import { getAllCatalogs } from './catalog/catalogs/catalog.selectors';
import { getAuthToken } from '@/redux/modules/account/user/user.selectors';
import { getDeployment } from './config';
import { LOAD_UPCOMING_CATALOGS_FAIL, LOAD_UPCOMING_CATALOGS_REQUEST, LOAD_UPCOMING_CATALOGS_SUCCESS } from './actions';
import { LOGIN_SUCCESS } from '@/redux/modules/account/login/login.types';
import ms from 'ms';

const REDUX_STORE_TIME = ms('30m');

export type UpcomingCatalogsSlice = {
    ids: any[];
    loaded: number;
    loading: boolean;
};

export const defaultUpcomingCatalogsSlice: UpcomingCatalogsSlice = {
    ids: [],
    loaded: 0,
    loading: false,
};

export default function reducer(
    state: UpcomingCatalogsSlice = defaultUpcomingCatalogsSlice,
    action: any = {}
): UpcomingCatalogsSlice {
    switch (action.type) {
        case LOAD_UPCOMING_CATALOGS_FAIL:
            return {
                ...state,
                loading: false,
            };
        case LOAD_UPCOMING_CATALOGS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case LOAD_UPCOMING_CATALOGS_SUCCESS:
            return {
                ...state,
                ids: action.payload.catalogs.map((catalog) => catalog.catalogId).sort(),
                loaded: action.meta.actionTime,
                loading: false,
            };
        case LOGIN_SUCCESS:
            return defaultUpcomingCatalogsSlice;
        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state: GlobalState) => state;
const upcomingCatalogsSlice = createSelector(stateSelector, (state) => state.upcomingCatalogs);

const loadedSelector = createSelector(upcomingCatalogsSlice, (state) => state.loaded);

const loadingSelector = createSelector(upcomingCatalogsSlice, (state) => state.loading);

export const getUpcomingCatalogIds = createSelector(upcomingCatalogsSlice, (state) => state.ids);

export const getLiveCatalogsIds = createSelector(
    // ! Circular dependency issue, shouldn't have to use state like this.
    [getUpcomingCatalogIds, (state) => getAllCatalogs(state)],
    (allUpcomingCatalogIds, catalogs) => {
        const upcoming = allUpcomingCatalogIds.map((catalogId: number) => catalogs[catalogId]);
        return upcoming.filter((catalog) => catalog.catalogStatus === 'live').map((catalog) => catalog.catalogId);
    }
);

const shouldFetchUpcomingCatalogs = createSelector(
    [getUpcomingCatalogIds, loadedSelector, loadingSelector],
    (items, loaded, loading) => {
        if (items.length) {
            const time = Date.now();
            const diff = time - loaded;
            if (diff < REDUX_STORE_TIME) {
                return false;
            }
        }
        return !loading;
    }
);

/* ACTION CREATORS */
const loadUpcomingCatalogs = (pageSize, today, forceUpdate) => async (dispatch, getState) => {
    try {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);
        dispatch({
            type: LOAD_UPCOMING_CATALOGS_REQUEST,
        });
        const response = await fetchUpcomingCatalogs({
            authToken,
            deployment,
            forceUpdate,
            pageSize,
            today,
        });
        dispatch({
            meta: { actionTime: Date.now() },
            payload: response.data,
            type: LOAD_UPCOMING_CATALOGS_SUCCESS,
        });
    } catch (error) {
        dispatch({
            error: true,
            payload: error,
            type: LOAD_UPCOMING_CATALOGS_FAIL,
        });
    }
};

export const fetchUpcomingCatalogsIfNeeded =
    (pageSize: number, today: any, forceUpdate: boolean = false) =>
    async (dispatch: AppDispatch, getState: AppGetState) => {
        if (forceUpdate || shouldFetchUpcomingCatalogs(getState())) {
            return dispatch(loadUpcomingCatalogs(pageSize, today, forceUpdate));
        }
        return Promise.resolve();
    };
