import { createSelector } from '@reduxjs/toolkit';
import { GlobalState } from '@/redux/store';

const stateSelector = (state: GlobalState) => state;
const walkthroughsSlice = createSelector(stateSelector, (state) => state.persist.walkthroughs);

export const onboardingWalkthroughSelector = createSelector(walkthroughsSlice, (state) => state.onboardingWalkthrough);
export const onboardingWalkthroughCurrentStep = createSelector(
    onboardingWalkthroughSelector,
    ({ currentStep }) => currentStep
);

export const onboardingWalkthroughIsOpen = createSelector(onboardingWalkthroughSelector, ({ isOpen }) => isOpen);
