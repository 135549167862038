import { createSelector } from '@reduxjs/toolkit';
import { GlobalState } from '@/redux/store';
import { OPEN_WAITING_FOR_APPROVAL_MODAL } from './actions';

/* reducer */
export type WaitingForApprovalSlice = {
    catalogId: number;
    hideUrgentMessages: boolean;
};

export const defaultWaitingForApprovalSlice: WaitingForApprovalSlice = {
    catalogId: 0,
    hideUrgentMessages: false,
};

export default function reducer(
    state: WaitingForApprovalSlice = defaultWaitingForApprovalSlice,
    action: any = {}
): WaitingForApprovalSlice {
    switch (action.type) {
        case OPEN_WAITING_FOR_APPROVAL_MODAL:
            return {
                ...state,
                catalogId: action.payload.catalogId,
                hideUrgentMessages: Boolean(action.payload?.hideUrgentMessages),
            };
        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state: GlobalState) => state;
const waitingForApprovalSlice = createSelector(stateSelector, (state) => state.waitingForApproval);

export const getWaitingForApprovalCatalogId = createSelector(waitingForApprovalSlice, (state) => state.catalogId);

export const getHideUrgentMessages = createSelector(waitingForApprovalSlice, (state) => state.hideUrgentMessages);
