import { createSelector } from '@reduxjs/toolkit';
import { GlobalState } from '@/redux/store';
import { UPCOMING_ITEMS_CACHE_TIME } from '@/redux/modules/upcoming/items/upcomingItems.types';

const stateSelector = (state: GlobalState) => state;
const upcomingItemsSlice = createSelector(stateSelector, (state) => state.upcomingItems);

export const getHasLoadedUpcomingIds = createSelector(upcomingItemsSlice, (state) => Boolean(state.loaded));

export const getUpcomingItemIds = createSelector(upcomingItemsSlice, (state) => state.itemIds || []);

export const shouldFetchUpcomingItems = createSelector(upcomingItemsSlice, (state) => {
    const { loaded, submitted } = state;
    if (submitted) {
        return false;
    }

    const time = Date.now();
    const diff = time - loaded;

    return diff >= UPCOMING_ITEMS_CACHE_TIME;
});
